
import CustomButton from '@/common/button/CustomButton.vue';
import {defineComponent} from 'vue';
import User from "@/user/model/User";
import UserInfo from "@/user/model/UserInfo";

interface Data {
  currentUser: User | null,
  userInfo: UserInfo | null,
  emailVerificationLoading: boolean,
  showVerifyEmailMessage: boolean
}


export default defineComponent({
  components: {CustomButton},
  data(): Data {
    return {
      currentUser: null,
      userInfo: null,
      emailVerificationLoading: false,
      showVerifyEmailMessage: false,
    }
  },
  methods: {
    async fetchUser(): Promise<void> {
      if (!this.currentUser) {
        this.currentUser = await this.$ports.user.getAuthenticatedUser();
      }
      if (this.currentUser) {
        this.userInfo = await this.$ports.user.getUserInfo();
      }
    },
    editProfile() {
      this.$router.push({
        name: 'user-profile-edit',
      });
    },
    editPassword() {
      this.$router.push({
        name: 'user-password-edit',
      })
    },
    changeRole() {
      this.$router.push({
        name: 'user-role-select',
      })
    },
    async verifyEmail() {
      this.emailVerificationLoading = true;
      let successful = await this.$ports.user.sendVerificationEmail();
      this.emailVerificationLoading = false;
      if (successful) {
        this.showVerifyEmailMessage = true;
        setTimeout(() => this.showVerifyEmailMessage = false, 10000);
      }
    }
  },
  created() {
    this.fetchUser();
  },
})


